/*
 * @Statement: 本代码版权及相关法律权利归作者所有，任何形式的引用、集成、修改、发行、销售等均需经作者书面授权。
 * @Author: Jenn@18179268111
 * @Date: 2021-04-01 15:53:58
 * @LastEditors: Jenn
 * @LastEditTime: 2021-04-06 10:25:12
 * @FilePath: /hblg-site-dev/src/router/index.js
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
	{
		name: 'home',
		path: '/',
		redirect: 'xiaofang',
		component: () => import('@/views/home.vue'),
		children: [
			{
				name: 'xiaofang',
				path: 'xiaofang',
				component: () => import('@/views/app/xiaofang.vue'),
			},
			{
				name: 'anfang',
				path: 'anfang',
				component: () => import('@/views/app/anfang.vue'),
			},
			{
				name: 'xiaoyuan',
				path: 'xiaoyuan',
				component: () => import('@/views/app/xiaoyuan.vue'),
			},
			{
				name: 'yuanqu',
				path: 'yuanqu',
				component: () => import('@/views/app/yuanqu.vue'),
			},
		],
	},
];

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
