<!--
 * @Statement: 本代码版权及相关法律权利归作者所有，任何形式的引用、集成、修改、发行、销售等均需经作者书面授权。
 * @Author: Jenn@18179268111
 * @Date: 2021-04-01 15:53:58
 * @LastEditors: Jenn
 * @LastEditTime: 2021-04-06 12:52:56
 * @FilePath: /hblg-site-dev/src/App.vue
-->
<style scoped lang="less">
.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>

<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
export default {
  data() {
    return {
      loading: false,
      locale: zh_CN,
    };
  },
};
</script>
